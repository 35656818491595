export const w = typeof window !== "undefined" ? window : undefined;

type TargetElement = HTMLElement | null | undefined;

export interface TrackPropertyOptions<P> {
  el: TargetElement;
  startTrackingOnEvents: (keyof GlobalEventHandlersEventMap)[];
  property: P;
  round?: boolean;
}

export const trackProperty = <P extends keyof HTMLElement>(
  callback: (value: HTMLElement[P] | null) => void,
  { el, startTrackingOnEvents: events, property, round = true }: TrackPropertyOptions<P>
): (() => void) => {
  if (el) {
    const p = w?.getComputedStyle(el).getPropertyValue("position");

    if (p !== "absolute" && p !== "fixed")
      // eslint-disable-next-line
      console.warn(
        `The following element will cause page reflows while tracking the "${property}" property since its position is set to ${p}. Set its position to "absolute" or "fixed" to avoid this.`,
        el
      );
  }

  let prev: HTMLElement[P] | null;
  let run = false;
  let init = true;

  const f = () => {
    const current = el?.[property] ?? null;

    if (current === prev) run = false;
    else prev = current;

    if (init || run)
      callback(
        round && typeof current === "number" ? (Math.round(current) as HTMLElement[P]) : current
      );
    if (run) requestAnimationFrame(f);

    init = false;
  };

  const listener = () => !run && ((run = true), f());

  events.forEach((e) => {
    el?.addEventListener(e, listener);
  });

  f();

  return () => {
    run = false;

    events.forEach((e) => {
      el?.removeEventListener(e, listener);
    });
  };
};
